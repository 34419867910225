import React from "react";
import "./Next.css";
import image1 from "./images/yourStory.png";
import image2 from "./images/business.png";
import image3 from "./images/Inc42.png";

// style={{border:'5px solid black'}}

function Next() {
  return (
    <>
      <div className="Next conntiner-fluid " >
        <div>
          <div className="topnext  " >
            <h4 className="text-center ">
              In the news
            </h4>
          </div>
          <div className="Nextfooter pt-5">

            <a href="#" ><img src={image1} height={90} /></a>


            <a href="#" ><img src={image2} height={50} /></a>


            <a href="#"><img src={image3} height={60} /></a>

          </div>
        </div>
      </div>
    </>
  );
}

export default Next;
