import React from 'react';
import './Button.css';

const Switch = (props) => {

function changeColor(id){
    console.log("flag", id)
      document.getElementsByClassName("togg")[0].style.color="#777";
      document.getElementsByClassName("togg")[1].style.color="#777";
      document.getElementById(id).style.color="white";
    }

   
  return (
    <>
      <input
        className="react-switch-checkbox"
        id={`react-switch-new`}
        type="checkbox"
      />
      <label
        className="react-switch-label"
        htmlFor={`react-switch-new`}
      >
          <p className='togg' id={'first'+ props.id} onClick={() => changeColor("first"+ props.id)}>HIRE TALENT</p>
          <p className='togg' id={'second'+props.id} onClick={() => changeColor("second"+ props.id)}>SHARE TALENT</p>
          
        <span className={`react-switch-button`} />
      </label>
    </>
  );
};

export default Switch;