import React from 'react'
import How from './How';
import Why from './Why';
import Slider_new from './Slider_new';
import Next from './Next';
import Postjob from './Postjob';
import Subscribe from './Subscribe';
// import Button from './Button';
import './Postjob.css';
import './Button.css';
import './Slider_new.css';
import './Header.css';
import './Button.css';
import './Why.css';
import './Subscribe.css';
import './Slider_new.css';
import './Footer.css';
import './Next.css';
import './How.css';

function Home() {
    return (
        <>
            <Postjob />
            <Why />
            <How />
            <Next />
            <Slider_new />
            <Subscribe />
        </>
    )
}

export default Home