import React from "react";
import { Link } from "react-router-dom";
import image from "./images/footerlogo.png";


function Header() {
    return (

        <>
            <div className="Header px-5 p-5" >
                <nav className="navbar   navbar-expand-lg text-light shadow-lg p-3 mb-5 bg-white ">
                    <div className="container-fluid">
                        <Link to="/">
                            <a className="navbar-brand" href="#"><img src={image} height={40} /></a>
                        </Link>
                        <button className="navbar-toggler " type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon text-dark bg-dark"></span>
                        </button>
                        <div className="collapse navbar-collapse bg-light" id="navbarSupportedContent">
                            <ul className="navbar-nav me-auto mb-2 mb-lg-0 ">
                                <li className="nav-item ">
                                    <Link to="case" className="text-decoration-none">
                                        <a className="nav-link active text-dark" aria-current="page" href="#">CASE STUDY</a>
                                    </Link>
                                </li>

                                <li className="nav-item ">
                                    <Link to="partner" className="text-decoration-none">
                                        <a className="nav-link active text-dark" aria-current="page" href="#">PARTNER</a>
                                    </Link>
                                </li>
                                <li className="nav-item ">
                                    <Link to="blog" className="text-decoration-none">
                                        <a className="nav-link active text-dark" aria-current="page" href="#">BLOG</a>
                                    </Link>
                                </li>
                            </ul>
                            <button className='Headerbtns'>GET STARTED</button>

                        </div>
                    </div>
                </nav>
                <div className=" lower border  text-center">
                    <h1 className="mt-5 text-center " style={{ fontWeight: "800" }}>Foolproof your company <br />  against fluctuations in demand</h1>


                    <div className="small">
                        <div>
                            <h4 className="text-light">with a platform connecting companies to share full-time employees.</h4>
                        </div>
                        <div className="button">
                            <button className='Headerbtn' id="get_start">GET STARTED</button>
                            <button className='Headerbtn' id="get_hire">GET A DEMO</button>
                        </div>
                    </div>
                </div>

            </div>


        </>

    );
}

export default Header;
