import React from 'react'
import './App.css';

import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/bootstrap/dist/js/bootstrap.bundle"
// import 'bootstrap/dist/css/bootstrap.min.css';
// import 'bootstrap/dist/js/bootstrap.js';
// import 'bootstrap/dist/js/bootstrap.bundle.js';
// import 'bootstrap/js/dist/tooltip.js';
// // import 'slick-carousel/slick/slick.css';
// import 'slick-carousel/slick/slick-theme.css';
import Header from './Component/Header';
import Footer from './Component/Footer';
import CaseStudy from './Component/CaseStudy';
import Partner from './Component/Partner';
import HomePage from './Component/HomePage';
import Blog from './Component/pages/Blog';
import { BrowserRouter, Routes, Route, Link } from 'react-router-dom';


function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Header />
        <Routes>
          <Route path="/" element={<HomePage />}></Route>
          <Route path="/case" element={<CaseStudy />}></Route>
          <Route path="/partner" element={<Partner />}> </Route>
          <Route path="/blog" element={<Blog />}></Route>
        </Routes>
      </BrowserRouter>
      <Footer />
    </div>
  );
}

export default App;
