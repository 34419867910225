import React from 'react'
import img from './images/ss.svg';

function Subscribe() {
  return (
    <>
      <div className='Subscribecontainer'>
        <div className='Subscribeimage'>
          <img id='img' src={img} />


        </div>

        <div className='Subscribetext '>
          <h1>Subscribe Us</h1>
          <p>To Get Regular Updates</p>
          <input placeholder={"Work email address"} />
          <button className='Subscribebtn'>Subscribe</button>
        </div>
      </div>


    </>
  )
}

export default Subscribe