import React from "react";
import { Button, ButtonGroup } from "@chakra-ui/react";

function Why() {
  return (
    <>
      <div className="Why">
        <div className="heading">
          <div className="you">
            {" "}
            <h1>Why</h1>
          </div>
          
          <div className="buttons_why">
            <button className="postjobbtn">Hire </button>
          </div>

          <div className="buttons_why" id="btn_why">
            <button className="postjobbtn" id="btnn_why"  >Share </button>
          </div>


          <div className="yes">
            {" "}
            <h1>a talent via Revolution Software</h1>
          </div>
        </div>

        <div className="Why_footer">
          <div className="Why_post">
            <h2>Post a job</h2>
            <p1>Post a requirement with hello are you.</p1>
          </div>
          <div className="Why_get">
            <h2>Get Matched</h2>
            <p1>Post a requirement with hello are you.</p1>
          </div>
          <div className="Why_deploy">
            <h2>Deploy Talent</h2>
            <p1>Post a requirement with hello are you.</p1>
          </div>
        </div>
      </div>
    </>
  );
}

export default Why;
