import React from "react";
import "./How.css";
import Button from "./Button";
// import Font, { Text } from 'react-font'

import { AiFillFileText } from "react-icons/ai";


import { IoDocuments } from "react-icons/io5";

import { BsFillPersonPlusFill } from "react-icons/bs";
import { BsFillBookmarkCheckFill } from "react-icons/bs";


function How() {

  return (
    <>
      <div className="How">

        {/* <div className="How_header">
              <h1 className="How_it">How it works to</h1>
               <Button className='How_button'/>
              <h1 className="How_talent">a talent</h1>

          </div> */}
        <div className="Heading">
          <div className="you"> <h1>How it works to</h1></div>
          {/* <div className="hello"> <Button /></div> */}
          <div className="buttons_why">
            <button className="postjobbtn">Hire </button>
          </div>

          <div className="buttons_why" id="btn_why">
            <button className="postjobbtn" id="btnn_how"  >Share </button>
          </div>
          <div className="yes">  <h1>a talent</h1></div>
        </div>






        <div className="How_footer">
          <div className="How_post " >
            <AiFillFileText style={{ color: "#3b54ec" }} size="50px" className="How_icon" />
            <h2>Post a job</h2>
            <p className="How_padding">Post a requirement with basic details like job description, skills, project tenure etc.</p>
          </div>
          <div className="How_get">
            <IoDocuments style={{ color: "#3b54ec" }} size="50px" className="How_icon" />
            <h2>Get Matched</h2>
            <p className="How_padding">Get profiles of immediate joiners matching your job. Schedule interview with the profiles you like.</p>
          </div>
          <div className="How_deploy">

            <BsFillPersonPlusFill style={{ color: "#3b54ec" }} size="50px" className="How_icon" />
            <h2>Deploy Talent</h2>
            <p className="How_padding">Post interviews, onboard the selected talent onto your project from the next day itself.</p>
          </div>
          <div className="How_support">
            <BsFillBookmarkCheckFill style={{ color: "#3b54ec" }} size="40px" className="How_icon" />
            <h2>24*7 Support</h2>
            <p className="How_padding">Revolution Software remains in constant touch for any help required till the project is completed.</p>
          </div>
        </div>
      </div>
    </>
  );
}

export default How;
