import React from 'react'
import imgl from './images/footerlogo.png'
import imgs from './images/100x.png'
import imgg from './images/101x.png'

function Footer() {
    return (
        <>
            <div className='footercontainermain'>
                <div className='footercontainer'>
                    <div className='row'>
                        <img id='hmd' src={imgl} />
                        <div className='col-md-3 col-sm-6'>
                            <h2 className='head'>Main</h2>
                            <ul className='list-unstyled'>
                                <li>Home</li>
                                <li>Careers</li>
                                <li> <link rel="stylesheet" href="#./" /> Blog</li>
                                <li>Case Study</li>
                            </ul>
                        </div>
                        <div className='col-md-3 col-sm-6'>
                            <h2 className='head'>Legal</h2>
                            <ul className='list-unstyled'>
                                <li>Terms of Use</li>
                                <li>Privacy Policy</li>
                                <li>Version : Beta 2</li>
                            </ul>
                        </div>
                        <img src={imgs} />
                        <img src={imgg} />
                    </div>
                    <hr />
                    {/* Footer bottom */}
                    <div className='footerbottom'>
                        <p className='text-xs-center'>
                            {new Date().getFullYear()} copy guide App- Revolution software services pvt.ltd.
                        </p>
                    </div>
                </div>

            </div>
        </>
    )
}

export default Footer