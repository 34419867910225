import React from "react";

import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.min.css";
import "owl.carousel/dist/assets/owl.theme.default.min.css";

import img1 from "./images/Img1.jpg";
import img2 from "./images/Img2.png";
import img3 from "./images/Img3.jpg";
import img4 from "./images/Img4.jfif";
import img5 from "./images/Img7.jpg";
import img6 from "./images/Img9.webp";

function Slider_new() {
  return (
    <>
      <div className="CONtent">
        <div className="top-Text">
          <h1>Our Clients</h1>
        </div>
        <div className="carousel">
          <OwlCarousel
            className="owl-theme"
            items={5}
            autoplay
            // nav
            dots
            loop>


            <div className="item">
              <img src={img1} id="cignex" />
            </div>
            <div className="item">
              <img src={img2} />
            </div>
            <div className="item">
              <img src={img3} />
            </div>
            <div className="item">
              <img src={img4} id="tricolor" />
            </div>
            <div className="item">
              <img src={img5} />
            </div>
            <div className="item">
              <img src={img6} />
            </div>
          </OwlCarousel>
        </div>
      </div>

    </>
  );
}

export default Slider_new;
