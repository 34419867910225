import React from "react";
import img from "./images/image.jpeg";
import Button from "./Button";

function Postjob() {
  return (
    <>
      <div className="postjobcontainer">
        <div className="postjobtext">
          <div className="btn">
            <Button id={1} />
            <br />
            <br />
          </div>
          <h1>Hire talent without notice period.</h1>
          <p>
            Find immediate joiners from a pool of skilled talent shared by 100+{" "}
            <br /> companies like you to hire them on a project basis.
          </p>
          <div className="buttons">
            <button className="postjobbtn">POST JOB</button>
          </div>
        </div>
        <div className="postjobimage">
          <img id="photo" src={img} className="shadow-lg" />
        </div>
      </div>
    </>
  );
}

export default Postjob;
